import React from "react"

export interface CompanyBlockCardProps {}

const CompanyBlockCard: React.SFC<CompanyBlockCardProps> = () => {
  return (
    <div className="content-company-card">
      <figure>
        <iframe
          src="https://www.youtube.com/embed/C2Ebu09ArDo"
          id="_dytid_5419"
        ></iframe>
      </figure>
      <p>
        弊社の業務内容や代表の鹿毛の起業した際のストーリーなどを動画でお話させていただきました。
      </p>
    </div>
  )
}

export default CompanyBlockCard
