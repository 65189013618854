import React, { useEffect } from "react"
// Layout
import LayoutComponent from "../components/LayoutComponent"
// Blocks
import AsideBlock from "../blocks/articles-page/aside.block"
import CompanyBlockCard from "../blocks/company-page/company.block"
import { Seo } from "../components/seo"
export interface CompanyBusinessProps {
  path:any;
}

const CompanyBusiness: React.SFC<CompanyBusinessProps> = (props: {
  path: any
}) => {
  useEffect(() => {
    if (props.path !== "/") {
      const HEADER = document.querySelector(".content-header")
      const HEADER_MOBIL = document.querySelector(".header-mobile")
      HEADER?.classList.add("swap-header")
      HEADER_MOBIL?.classList.add("change-backgorund")
    }
  }, [])
  return (
    <LayoutComponent>
      <Seo
        title="Super Sonic Design INC | 会社・業務内容について"
        url="https://supersonicdesign.com/company-business"
        description="弊社の業務内容や代表の鹿毛の起業した際のストーリーなどを動画でお話させていただきました。"
        image = ""
        author = ""
        keywords = "Super Sonic Design INC | 会社・業務内容について"
      />
      <div className="container container-articles">
        <div className="row no-gutters">
          <div className="col-lg-9">
            <div className="container">
              <h3>会社・業務内容について</h3>
              <div className="row">
                {[1].map((card, i) => (
                  <div className="col-lg-12" key={i}>
                    <CompanyBlockCard />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <AsideBlock />
          </div>
        </div>
      </div>
    </LayoutComponent>
  )
}

export default CompanyBusiness
